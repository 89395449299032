import { Card, Icon } from '@dayinsure/components';

type PolicyInfoContactCardsProps = {
  displayRAC: boolean;
  isRACAddOn?: boolean;
  testId: string;
  className?: string;
};

export const ContactCards = ({
  displayRAC = false,
  isRACAddOn = false,
  testId,
  className,
}: PolicyInfoContactCardsProps) => (
  <section className={className}>
    <Card
      classNames="flex items-center my-8"
      paddingLevel="small"
      testId={`${testId}_mulsanne-claims`}
    >
      <Icon className="text-main-content-1" size="2rem" name="phone" />
      <div className="ml-6 text-main-content-1">
        All claims are handled by Mulsanne. Should you need to make a claim on your
        policy, please call Mulsanne on
        <a href="tel:03332076598" className="ml-2 font-bold whitespace-nowrap text-link">
          0333 2076598
        </a>
        .
      </div>
    </Card>

    {displayRAC && (
      <Card
        classNames="flex items-center"
        paddingLevel="small"
        testId={`${testId}_RAC-phone`}
      >
        <Icon className="text-main-content-1" size="2rem" name="phone" />
        <div className="ml-6 text-main-content-1">
          {isRACAddOn
            ? 'If you are unfortunate enough to break down please call RAC on'
            : 'If you have purchased breakdown cover and are unfortunate enough to break down please call RAC on'}
          <a
            href="tel:0330 1590303"
            className="ml-2 font-bold whitespace-nowrap text-link"
          >
            0330 1590303
          </a>
          .
        </div>
      </Card>
    )}
  </section>
);
