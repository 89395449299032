import { ContentContainer } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { PoliciesPaths } from '../../../../routes';
import { ContactCards, PolicySummaryCard } from '../../../../components';
import {
  useMotorPolicySummaryQuery,
  usePolicyQuery,
  useResetFormState,
  useQuoteQuery,
} from '../../../../hooks';
import { PolicyDocuments } from '../../../../components/PolicyDocuments';
import { CardWithLogin } from '../../../../components/CardWithLogin';
import { hasRACAddOn } from '../../../../helpers/policy/hasRACAddOn';
import { PolicyPendingChangesMessage } from '../../PolicyInfo/PolicyInfoPendingChanges';

const testId = 'changes-complete';
export const ChangesComplete = () => {
  useResetFormState();
  const { quoteId, id } = useParams<{ quoteId: string; id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const { data } = useMotorPolicySummaryQuery(quoteId);
  const { data: quote } = useQuoteQuery(quoteId);
  const motorQuote = data?.[0];

  return (
    <div className="mb-8 w-full md:mb-16">
      <ContentContainer>
        <h1
          className="mt-8 text-xl text-center md:mt-12 text-main-content-1 lg:mt-15"
          data-testid={`${testId}_title`}
        >
          Changes complete
        </h1>
        <h2
          className="my-8 text-lg text-center md:mt-12 lg:mt-12 text-main-content-1"
          data-testid={`${testId}_subtitle`}
        >
          Thank you for using us for your car insurance
        </h2>
        {policy?.pendingChanges && policy?.pendingChanges?.effectiveDateTimeUtc && (
          <PolicyPendingChangesMessage
            testId={testId}
            changesStartDateUtc={policy?.pendingChanges?.effectiveDateTimeUtc}
            policyVersion={policy?.pendingChanges?.policyVersion}
            customNavLink={`../../../${PoliciesPaths.UpcomingChanges}`}
          />
        )}
        {motorQuote && (
          <PolicySummaryCard
            plateNo={motorQuote.vehicleRegistration || ''}
            carDetails={motorQuote.vehicleDescription || ''}
            progress={motorQuote.policyProgress || 0}
            buttonLink={`/${PoliciesPaths.Policies}/${id}`}
            testId={testId}
            activityDate={`Ends ${formatInUKLocalTime(
              'dd/MM/yyyy',
              motorQuote.endDate
            )} at ${formatInUKLocalTime('HH:mm', motorQuote.endDate)}`}
            className="bg-content-background-prominent"
          />
        )}
        <CardWithLogin testId={`${testId}_info-documents`} />
        {motorQuote?.id && (
          <PolicyDocuments
            className="mt-8 lg:mt-12"
            testId={testId}
            policyId={motorQuote?.id}
          />
        )}
        <ContactCards
          testId={testId}
          displayRAC={hasRACAddOn(quote?.addOns)}
          isRACAddOn
        />
      </ContentContainer>
    </div>
  );
};
